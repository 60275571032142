<template>
  <div>
    <el-form-item label="Opis" prop="description">
      <vue-editor v-model="local_value" use-custom-image-handler @image-added="handleImageAdded" :editorOptions="editorSettings"/>
    </el-form-item>
    <AddHtmlDialog
      v-model="htmlContent"
      :dialog-visible="insertDialog"
      @dialogContentChanged="updateValHtml"
      @close="insertDialog = false"
    />
  </div>
</template>
  
  <script>
  import { VueEditor,Quill } from 'vue2-editor';
  import NewsletterResource from '@/api/newsletter';
  const newsletterResource = new NewsletterResource();

  import ImageResize from 'quill-image-resize-vue';
  import { ImageDrop } from 'quill-image-drop-module';

  Quill.register("modules/imageDrop", ImageDrop);
  Quill.register("modules/imageResize", ImageResize);

  import AddHtmlDialog from '@/views/newsletter/AddHtmlDialog';

  

  var icons = Quill.import('ui/icons');
  icons['undo'] = '<svg width="800px" height="800px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><rect x="0" fill="none" width="20" height="20"/><g><path d="M4 16v-2H2v2H1v-5h1v2h2v-2h1v5H4zM7 16v-4H5.6v-1h3.7v1H8v4H7zM10 16v-5h1l1.4 3.4h.1L14 11h1v5h-1v-3.1h-.1l-1.1 2.5h-.6l-1.1-2.5H11V16h-1zM19 16h-3v-5h1v4h2v1zM9.4 4.2L7.1 6.5l2.3 2.3-.6 1.2-3.5-3.5L8.8 3l.6 1.2zm1.2 4.6l2.3-2.3-2.3-2.3.6-1.2 3.5 3.5-3.5 3.5-.6-1.2z"/></g></svg>';

  export default {
    name: 'NewsletterDescription',
    components: {
      VueEditor,
      AddHtmlDialog
    },
    props: {
      valHtml: {
        type: String,
        default() {
          return '';
        },
      },
      value: {
        type: String,
        default() {
          return '';
        },
      },
    },
    data() {
      return {
        local_value: '',
        htmlContent: '',
        insertDialog: false,
        editorSettings: {
            modules: {
                imageDrop: true,
                imageResize: {},
                toolbar:{
                    container: [
                        ['bold', 'italic', 'underline'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        ["image"],
                        [{undo: 'undo'}]
                    ],
                    handlers: {
                        undo: () => {
                            var self = this;
                            this.openInsertDialog();
                        },
                    }
                }
            },
        }
      };
    },
    methods: {
        async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
        const formData = new FormData();
        formData.append('image', file);
        const result = await newsletterResource.imgStore(formData);
        console.log(result)
        Editor.insertEmbed(cursorLocation, 'image', result);
        resetUploader();

      },
      openInsertDialog() {
        this.insertDialog = true;
      },
      updateValHtml(value) {
        this.htmlContent = value;
        this.local_value = this.htmlContent;
      },
    },
    watch: {
      value(value) {
        if (value !== this.local_value) {
          this.local_value = this.value;
          this.$emit('set-value', value);
        }
      },
      local_value(value) {
        this.$emit('set-value', value);
      },
    },
  };
  </script>
  
  <style scoped>
  
  </style>
  