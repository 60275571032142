import Resource from '@/api/resource';
import request from '@/utils/request';

class NewsletterResource extends Resource {
  constructor() {
    super('admin/newsletter');
  }

  store(resource) {
    return request({
      url: '/' + this.uri,
      method: 'post',
      data: resource,
    });
  }

  imgStore(resource) {
    return request({
      url: '/' + this.uri + '/store-image',
      method: 'post',
      data: resource,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  usersList(resource) {
    return request({
      url: '/' + this.uri + '/usersList',
      method: 'get',
      data: resource,
    });
  }

  export(resource) {
    return request({
      url: '/' + this.uri + '/export',
      method: 'get',
      data: resource,
    });
  }

}

export { NewsletterResource as default };
